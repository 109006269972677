<template>
  <b-card id="card-map-admin">
    <b-row>
      <b-col>
        <transition name="slided-fade">
          <gmap-map
            id="gmap-map"
            ref="gmap-map"
            v-bind:center="center"
            v-bind:zoom="zoom"
            style="width: 100%; height: 85vh"
          >
            <gmap-info-window
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
              style="gmap-info-window-vue"
            >
              <div class="infoWindow">
                <div v-html="infoOptions.content"></div>
              </div>
            </gmap-info-window>
            <gmap-marker
              v-for="(item, i) in ots"
              :key="`${i}_OTS_`"
              :position="item.position"
              :clickable="true"
              :animation="2"
              :icon="item.icon"
              @click="toggleInfoWindow(item, i)"
            />

            <!-- Inicio brigada - rutas  -->
            <gmap-marker
              v-for="(item, i) in OTM"
              :key="i + '__OTM'"
              :position="item.position"
              :clickable="true"
              :animation="2"
              :icon="item.icon"
              @click="toggleInfoWindow(item, i)"
            />
            <gmap-polyline
              v-bind:path.sync="technicalRoute"
              :options="poly"
              deepWatch
            />
            <!-- fin brigadas -->
            <gmap-marker
              v-for="(item, i) in items"
              :key="i + '__OT'"
              :position="item.position"
              :clickable="true"
              :animation="2"
              :icon="item.icon"
              @click="toggleInfoWindow(item, i)"
            />
            <gmap-marker
              :key="i + '_TECH_'"
              v-for="(tech, i) in techMarker"
              :position="tech.position"
              :clickable="true"
              :animation="2"
              :visible="tech.visible && activeTech"
              :icon="tech.icon"
              @click="toggleInfoWindow(tech, i)"
            >
            </gmap-marker>
            <gmap-polyline v-bind:path.sync="path" v-bind:options="options" />
            <gmap-polyline
              v-bind:path.sync="pathOt"
              v-bind:options="optionsOt"
            />
          </gmap-map>
        </transition>
      </b-col>
    </b-row>
  </b-card>
</template>
<style>
.card-map-admin {
  padding: 0 !important;
}
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import can from "@/permission";
import _ from "lodash";
const symbolOne = {
  path: 0,
  strokeColor: "#0B945C",
  fillColor: "#0B945C",
  fillOpacity: 1,
  strokeWeight: 2,
};
const symbolMedium = {
  path: 1,
  strokeColor: "#0B5C6E",
  fillColor: "#0B5C6E",
  strokeWeight: 3,
  fillOpacity: 1,
};

const symbolEnd = {
  path: 0,
  strokeColor: "#C42E10",
  rotation: 45,
  strokeWeight: 2,
};

import ENV from "@/environment";
// ICONS_DEV - ICONS
const URL_ICON =
  process.env.NODE_ENV == "development" ? ENV.ICONS_DEV : ENV.ICONS;

const URL_ICONS = (icon) => {
  return `${URL_ICON}${icon}.png`;
};
import axios from "axios";
import toastr from "toastr";
import infoWindow from "@/assets/content/admin/infoWindowOpen";
import infoWindowTech from "@/assets/content/admin/infoWindowOpenTech";
import infoWindowVehicle from "@/assets/content/admin/infoWindowVehicle.js";
export default {
  data() {
    return {
      center: { lat: 10.9784174, lng: -74.8144132 },
      path: [],
      zoom: 12,
      options: {
        strokeColor: "rgba(29, 166, 197, 0.7)",
        strokeWeight: 2,
        strokeOpacity: 1.0,
        icons: [
          {
            icon: symbolOne,
            offset: "0%",
          },
          {
            icon: symbolMedium,
            offset: "25%",
          },
          {
            icon: symbolMedium,
            offset: "50%",
          },
          {
            icon: symbolMedium,
            offset: "75%",
          },
          {
            icon: symbolEnd,
            offset: "100%",
          },
        ],
      },
      pathOt: [],
      optionsOt: {
        strokeColor: "rgba(29, 120, 91, 0.7)",
        strokeWeight: 1,
        strokeOpacity: 1.0,
        icons: [
          {
            icon: symbolOne,
            offset: "0%",
          },
          {
            icon: symbolMedium,
            offset: "25%",
          },
          {
            icon: symbolMedium,
            offset: "50%",
          },
          {
            icon: symbolMedium,
            offset: "75%",
          },
          {
            icon: symbolEnd,
            offset: "100%",
          },
        ],
      },
      markers: [],
      items: [],
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        content: "",
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWinContent: null,
      techs: [],
      ots: [],

      // techs
      technicalRoute: [],
      workers: [],
      techMarker: [],
      OTM: [],
      poly: {
        strokeColor: "rgba(29, 166, 197, 0.7)",
        strokeWeight: 2,
        strokeOpacity: 1.0,
        icons: [
          {
            icon: symbolOne,
            offset: "0%",
          },
          {
            icon: symbolMedium,
            offset: "25%",
          },
          {
            icon: symbolMedium,
            offset: "50%",
          },
          {
            icon: symbolMedium,
            offset: "75%",
          },
          {
            icon: symbolEnd,
            offset: "100%",
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
    this.loadWorkers();
  },
  computed: {
    ...mapGetters({
      getIdx: "orders/getIdx",
      getCurrentIdx: "orders/getCurrentIdx",
      ordersOT: "orders/getInformationOT",
      getTecnical: "technicals/getTecnical",
      tech: "technicals/getTecnical",
      techId: "technicals/getTechId",
      getCurrentTech: "technicals/getCurrentTech",
      activeTech: "technical/getActive",
      getSectores: "auth/getSectores",
    }),
  },
  watch: {
    getIdx() {
      if (this.getIdx.length) {
        this.loadInformationOT(this.getIdx);
      } else {
        this.ots = [];
        this.infoWinOpen = false;
      }
    },
    techId() {
      this.mapMode = "ready";
      this.beforeTech(this.techId);
    },
  },
  methods: {
    ...mapActions({
      changeValueTech: "technicals/changeValueTech",
      loadOrders: "orders/loadWarningFromOT",
      setSectors: "auth/setSectors",
    }),
    can: can,
    init() {
      this.$nextTick(() => {
        this.$refs["gmap-map"].$mapPromise.then((map) => {
          map.setOptions({
            styles: [
              {
                featureType: "poi.business",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.medical",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.government",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.school",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.park",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.attraction",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.sports_complex",
                stylers: [{ visibility: "off" }],
              },
              {
                featureType: "poi.place_of_worship",
                stylers: [{ visibility: "off" }],
              },
            ],
          });
        });
      });
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.center = marker.position;
      this.infoOptions.content = marker.infoText;

      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    loadInformationOT(id) {
      if (id.length != 0) {
        axios
          .post(`get-ots`, { ots: id })
          .then((res) => {
            res.data.map((ot) => {
              let mak = [];
              ot.avisos.map((el) => {
                if (el.pivot.id_aviso_ref == el.id_aviso) {
                  mak.push({
                    id: el.id_aviso,
                    id_ot: ot.id_ot,
                    position: {
                      lat: parseFloat(el.latitud),
                      lng: parseFloat(el.longitud),
                    },
                    address: el.dir_suscriptor,
                    description: el.observacion,
                    priority: el.ind_peligro,
                    ordenNumber: el.num_aviso,
                    dir_ref: el.dir_ref,
                    ot: true,
                    type: el.tipo_trabajo,
                    ind_cli_vip: el.ind_cli_vip == 0 ? false : true,
                    icon: el.icon ? URL_ICONS(el.icon) : "",
                    infoText: infoWindow(el, ot),
                  });
                }
              });
              const [centered] = mak;
              this.center = centered.position;
              this.zoom = 14;
              this.loadOrders(mak);

              //corregir aqui
              this.ots = this.ordersOT;
              const [filtro] = this.ots.filter(
                (f) => f.id_ot === this.getCurrentIdx
              );
              if (filtro) {
                this.toggleInfoWindow(filtro, this.ots.length);
              }
              this.mapMode = "ready";
            });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.infoWinOpen = false;
        this.zoom = 12;
        this.ots = [];
      }
    },
    animateMarker(marker, coords, init) {
      var target = 0;
      var km_h = 30;
      coords.push([init.lat, init.lng]);

      const goToPoint = () => {
        var lat = marker.position.lat;
        var lng = marker.position.lng;
        var step = (km_h * 1000 * 100) / 3600000; // in meters

        var dest = new window.google.maps.LatLng(
          coords[target][0],
          coords[target][1]
        );

        var distance =
          new window.google.maps.geometry.spherical.computeDistanceBetween(
            dest,
            marker.position
          ); // in meters

        var numStep = distance / step;
        var deltaLat = (coords[target][0] - lat) / numStep;
        var deltaLng = (coords[target][1] - lng) / numStep;

        const moveMarker = () => {
          lat += deltaLat;
          lng += deltaLng;

          if (distance < 10) {
            marker.setPosition(window.google.maps.LatLng(lat, lng));
            setTimeout(moveMarker, 1000);
          } else {
            marker.setPosition(dest);
            target++;
            if (target == coords.length) {
              target = 0;
            }

            setTimeout(goToPoint, 1000);
          }
        };
        moveMarker();
      }
      goToPoint();
    },
    async loadWorkers() {
      let first = [];
      var data = {
        sectores: this.getSectores,
      };
      let response = await axios.post("brigadas", data);

      response.data = response.data.filter((r) =>
        data.sectores.includes(r.delegacion)
      );
      const load = () => {
        response.data = response.data.filter((r) =>
          data.sectores.includes(r.delegacion)
        );
        response.data.map((worker) => {
          if (
            worker.ultima_posicion !== null &&
            worker.cliente !== null &&
            worker.usuario_sesion !== null
          ) {
            if (worker.vehiculo_info) {
              first.push(
                new window.google.maps.Marker({
                  ot: worker.ots,
                  id: worker.id,
                  codempresa: worker.codempresa,
                  nombre: worker.nombre,
                  telefono: worker.telefono,
                  gps_pos: worker.gps_pos,
                  usuario: worker.usuario,
                  text: `${worker.cliente.vehiculo} - ${worker.cliente.nombre} - ${worker.cliente.movil}`,
                  technical: true,
                  usuario_sesion: worker.usuario_sesion,
                  token: worker.token,
                  ultima_posicion: worker.ultima_posicion,
                  status: worker.usuario_sesion.estado,
                  visible: worker.usuario_sesion.estado == 1,
                  position: {
                    lat: parseFloat(worker.ultima_posicion.latitud),
                    lng: parseFloat(worker.ultima_posicion.longitud),
                  },
                  icon: `${URL_ICON}tipo/${worker.vehiculo_info.tipo_vehiculo
                    .replace(/ /g, "")
                    .toLowerCase()}.png`,
                  infoText: infoWindowVehicle(worker),
                })
              );
            }
          }
        });

        this.zoom = 13;
        this.workers = first;
        this.techMarker = [];
        this.techMarker = first;
        // this.techMarker.map((el) => {
        //   const [init, last] = el.gps_pos;
        //   this.animateMarker(
        //     el,
        //     [
        //       [parseFloat(init.latitud), parseFloat(init.longitud)],
        //       [parseFloat(last.latitud), parseFloat(last.longitud)],
        //     ],
        //     50
        //   );
        // });
        this.changeValueTech(first);
      };
      load();
      setInterval(() => {
        if (this.$router.currentRoute.path === "/works") {
          load();
        }
      }, 150000);
    },
    async beforeTech() {
      if (this.getCurrentTech == -1) {
        this.OTM = [];
        this.technicalRoute = [];
        this.infoWinOpen = false;
        this.techMarker = [];
        this.techMarker = this.getTecnical;
        this.markers = [];
        this.workers = [];
        this.zoom = 11;
        this.techMarker.map((e) => {
          e.visible = e.status == 1;
        });
        if (this.dep == "ATLANTICO") {
          this.center = { lat: 10.9784174, lng: -74.8144132 };
        }
      } else {
        this.infoWinOpen = false;
        const filt = this.workers.filter(
          (f) => f.usuario == this.getCurrentTech
        );
        const filt2 = this.getTecnical.filter(
          (f) => f.usuario == this.getCurrentTech
        );
        const newCenter = filt.length == 0 ? filt2 : filt;

        this.techMarker = newCenter;

        this.OTM = [];

        if (newCenter.length) {
          const [info] = newCenter;
          info.visible = true;
          if (info.position) {
            this.center = info.position;
            const [Tmaker] = this.techMarker;
            this.toggleInfoWindow(Tmaker, 0);
          } else {
            toastr.info("Esta brigada no tiene una posición GPS.");
          }
          this.zoom = 13;
          this.infoWinOpen = true;

          if (info.ot.length == 0) {
            toastr.options = {
              closeButton: true,
            };

            toastr.info("Esta brigada no tiene ordenes asignadas");
          } else {
            let mak = [];
            this.technicalRoute = [];
            info.ot.map((orden) => {
              orden.avisos.map((el) => {
                const aviso = el.avisos_o_t_s;
                if (el.id_aviso == el.id_aviso_ref) {
                  this.technicalRoute.push({
                    lat: parseFloat(aviso.latitud),
                    lng: parseFloat(aviso.longitud),
                  });
                  this.technicalRoute = _.filter(
                    this.technicalRoute,
                    function (o) {
                      return !_.isNaN(o.lat) && !_.isNaN(o.lng);
                    }
                  );
                  mak.push({
                    id: aviso.id_aviso,
                    position: {
                      lat: parseFloat(aviso.latitud),
                      lng: parseFloat(aviso.longitud),
                    },
                    address: aviso.dir_suscriptor,
                    description: aviso.observacion,
                    priority: aviso.ind_peligro,
                    ordenNumber: aviso.num_aviso,
                    dir_ref: aviso.dir_ref,
                    icono: aviso.icono,
                    technicalWar: true,
                    type: aviso.tipo_trabajo,
                    ind_cli_vip: aviso.ind_cli_vip == 0 ? false : true,
                    icon: aviso.tipo_aviso
                      ? `${URL_ICONS(
                          aviso.tipo_aviso.replace(/ /g, "_").toLowerCase()
                        )}`
                      : "",
                    infoText: infoWindowTech(orden, aviso),
                  });
                }
              });
            });
            // this.reloadMarkerCenter(mak)
            this.OTM = mak;
            this.markers = this.warnings;
          }
        } else if (newCenter.length == 0) {
          this.zoom = 6;
          this.techMarker = this.getTecnical;
          toastr.warning(
            "la brigada no se encuentra disponible en estos momentos"
          );
        }
      }
    },
  },
};
</script>
